"use strict";

const {
  h
} = require('preact');

module.exports = props => {
  return h("div", {
    className: "uppy-Provider-loading"
  }, h("span", null, props.i18n('loading')));
};