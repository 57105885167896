"use strict";

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

const {
  h,
  Component
} = require('preact');

const RecordButton = require('./RecordButton');

const SubmitButton = require('./SubmitButton');

const StopWatch = require('./StopWatch');

const StreamStatus = require('./StreamStatus');

class RecorderScreen extends Component {
  componentWillUnmount() {
    this.props.onStop();
  }

  render() {
    const {
      recording,
      stream: videoStream,
      recordedVideo
    } = this.props;
    const videoProps = {
      playsinline: true
    }; // show stream

    if (recording || !recordedVideo && !recording) {
      videoProps.muted = true;
      videoProps.autoplay = true;
      videoProps.srcObject = videoStream;
    } // show preview


    if (recordedVideo && !recording) {
      videoProps.muted = false;
      videoProps.controls = true;
      videoProps.src = recordedVideo; // reset srcObject in dom. If not resetted, stream sticks in element

      if (this.videoElement) {
        this.videoElement.srcObject = undefined;
      }
    }

    return h("div", {
      className: "uppy uppy-ScreenCapture-container"
    }, h("div", {
      className: "uppy-ScreenCapture-videoContainer"
    }, h(StreamStatus, this.props), h("video", _extends({
      ref: videoElement => {
        this.videoElement = videoElement;
      },
      className: "uppy-ScreenCapture-video"
    }, videoProps)), h(StopWatch, this.props)), h("div", {
      className: "uppy-ScreenCapture-buttonContainer"
    }, h(RecordButton, this.props), h(SubmitButton, this.props)));
  }

}

module.exports = RecorderScreen;