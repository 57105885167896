"use strict";

const {
  h
} = require('preact');

module.exports = props => {
  return h("div", {
    className: "uppy-ProviderBrowser-footer"
  }, h("button", {
    className: "uppy-u-reset uppy-c-btn uppy-c-btn-primary",
    onClick: props.done,
    type: "button"
  }, props.i18n('selectX', {
    smart_count: props.selected
  })), h("button", {
    className: "uppy-u-reset uppy-c-btn uppy-c-btn-link",
    onClick: props.cancel,
    type: "button"
  }, props.i18n('cancel')));
};